<template>
  <div class="box_sorting">
    <div class="head_tbl">
      <div class="pos_left">
        <strong class="tit_text">결재선 지정</strong>
      </div>
    </div>
    <ul v-show="!isFixedApprovalLine" class="list_sorting sorting_type2">
      <li v-if="draftFlag != 3" class="item_sort">
        <em class="tit_txt">결재선 선택 <sup>*</sup></em>
        <div class="cont_sorting">
          <comm-opt-search
            ref="payAccount"
            method="GET"
            :allFlag="false"
            :url="`${this.$apiPath.APRVL_LINE_ACCONT}?useYn=Y&page=1&size=99`"
            :aprvlLineAccntList="aprvlLineAccntList"
            :classList="[]"
            :optionDesc="'계정 선택상자'"
            :validate="confirmChangePayAccount"
            :validateMsg="'계정 변경시 등록한 결재선과<br>자산 분류가 초기화 됩니다.<br>계정을 변경하시겠습니까?'"
            :optionName="payAccountName"
            :eventName="'select'"
            :isInit="true"
            @select="selectPayAccount"
          />
        </div>
      </li>
      <!-- <li class="item_sort">
        <em class="tit_txt">신청자 부서 <sup>*</sup></em>
        <div class="cont_sorting">
          <comm-opt
            :optionDesc="'부서 선택상자'"
            :optionName="draftDeptName"
            :optionList="draftDeptList"
            :eventName="'select'"
            :allFlag="false"
            @select="selectDraftDept"
          />
        </div>
      </li> -->
    </ul>
    <!-- // list_sorting -->
    <div :class="['info_line', { info_line_top: isFixedApprovalLine }]">
      <div class="item_line">
        <em class="tit_line">
          발제 부서 라인 <sup>*</sup>
          <p
            v-if="draftDeptApprLine.length < 1 && draftDeptApprLineAlertText"
            class="desc_tip tc_red"
          >
            <span class="ico_account ico_exclam_r" />{{ draftDeptApprLineAlertText }}
          </p>
        </em>
        <div class="box_search search_type2">
          <comm-inp-search
            :url="this.$apiPath.APRVL_ORGEMP"
            :initValue="''"
            method="get"
            inputRef="empInfo"
            :isDisplayResult="false"
            :displayData="displaySearchResult"
            @select="addDraftDeptAppr"
          />
          <button
            type="button"
            class="btn_search"
            @click.prevent="searchOrganization('draftApproval')"
          >
            <span class="ico_account ico_people">조직도 검색하기</span>
          </button>
        </div>
        <div class="group_people">
          <!-- 인풋과 함께 우측에 정렬될 때 : type_side 클래스 추가 -->
          <comm-item-emp
            :empName="me.empName || me.name"
            :accountId="me.accountId"
            :isTypeMe="me.typeMe"
          />
        </div>
        <draggable
          class="group_people"
          ghost-class="drag_on"
          :list="draftDeptApprLine"
          @start="drag = true"
          @end="drag = false"
        >
          <comm-item-emp
            v-for="(emp, index) in draftDeptApprLine"
            :key="index"
            :empName="emp.empName"
            :accountId="emp.accountId"
            :isTypeMe="emp.typeMe"
            @remove-employee="removeApproval(emp)"
          />
        </draggable>
      </div>
      <div class="item_line">
        <em class="tit_line">승인자 처리 라인 <sup>*</sup></em>
        <div class="box_search search_type2">
          <comm-inp-search
            ref="processLine"
            method="get"
            inputRef="empInfo"
            :url="this.$apiPath.APRVL_ORGEMP"
            :initValue="''"
            :isDisplayResult="false"
            :displayData="displaySearchResult"
            :isError="false"
            @select="addProcessingApproval"
          />
          <button
            type="button"
            class="btn_search"
            @click.prevent="searchOrganization('procApproval')"
          >
            <span class="ico_account ico_people">조직도 검색하기</span>
          </button>
        </div>
        <div ref="processingApprovalLine">
          <!-- 인풋과 함께 우측에 정렬될 때 : type_side 클래스 추가 -->
          <draggable
            class="group_people"
            ghost-class="drag_on"
            :list="processingApprovalLine"
            @start="drag = true"
            @end="drag = false"
          >
            <comm-item-emp
              v-for="(emp, index) in processingApprovalLine"
              :key="index"
              :empName="emp.empName"
              :accountId="emp.accountId"
              :isTypeMe="emp.typeMe"
              @remove-employee="removeApproval(emp)"
            />
          </draggable>
        </div>
      </div>
      <div class="item_line">
        <em class="tit_line">참조자</em>
        <div class="box_search search_type2">
          <comm-inp-search
            method="get"
            inputRef="empInfo"
            :url="this.$apiPath.APRVL_ORGEMP"
            :initValue="''"
            :isDisplayResult="false"
            :displayData="displaySearchResult"
            @select="addReferenceApproval"
          />
          <button
            type="button"
            class="btn_search"
            @click.prevent="searchOrganization('ccApproval')"
          >
            <span class="ico_account ico_people">조직도 검색하기</span>
          </button>
        </div>
        <div ref="referenceApprovalLine">
          <!-- 인풋과 함께 우측에 정렬될 때 : type_side 클래스 추가 -->
          <draggable
            class="group_people"
            ghost-class="drag_on"
            :list="referenceApprovalLine"
            @start="drag = true"
            @end="drag = false"
          >
            <comm-item-emp
              v-for="(emp, index) in referenceApprovalLine"
              :key="index"
              :empName="emp.empName"
              :accountId="emp.accountId"
              :isTypeMe="emp.typeMe"
              @remove-employee="removeApproval(emp)"
            />
          </draggable>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CommInpSearch from "@/_approval/components/common/CommInpSearch";
import CommOpt from "@/_approval/components/common/CommOpt";
import CommOptSearch from "@/_approval/components/common/CommOptSearch";
import CommItemEmp from "@/_approval/components/common/CommItemEmp";
import axios from "axios";
import { default as CommLayerMixin } from "@/_approval/mixins/common/commLayer.js";
import { default as CommDraggableMixin } from "@/_approval/mixins/common/commDraggable.js";
import { default as CommToastMixin } from "@/_approval/mixins/common/commToast.js";
import draggable from "vuedraggable";
import { mapState } from "vuex";
import { aprvlLineType } from "@/_approval/const/const";
import Vue from "vue";
import LocalStorageManager from "@/LocalStorageManager";
import ApiService from "@/services/ApiService";
import DraftWriteModel from "@/_approval/pages/purchaseDraft/write/DraftWriteModel";
import { escapeAmp } from "@/utils/stringUtils";
import commDocs from "@/_approval/mixins/common/commDocs";

Vue.prototype.$DraftBus = new Vue();

export default {
  ame: "DraftApprLine",
  components: { CommInpSearch, CommOpt, CommItemEmp, CommOptSearch, draggable },
  mixins: [CommLayerMixin, CommDraggableMixin, CommToastMixin, commDocs],
  props: {
    initData: Object,
    isFixedApprovalLine: {
      type: Boolean,
      default: false,
    },
    selectedApprovalLineAccount: Object,
    draftDeptApprLineAlertText: String,
  },
  data() {
    return {
      draftFlag: "1",
      payAccountName: "결재선 선택",
      payAccountList: [],
      draftDeptList: [],
      selectedDraftDeptCode: null,
      me: {
        typeMe: true,
        empNo: "",
        empName: "",
        accountId: "",
        deptName: "",
        deptCode: "",
        actorFlag: "D",
        actorState: "1",
        apprLineFlag: "1",
      },
      // 발제 부서 라인
      draftDeptApprLine: [],
      // 승인자 처리 라인
      processingApprovalLine: [],
      // 찬조자
      referenceApprovalLine: [],
      searchType: "",
      isTempOrReDraftPayAccount: false,
      isTempOrReDraftDraftDept: false,
      draftDeptName: "",
      aprvlLineAccntList: [],
    };
  },
  computed: {
    ...mapState("CommStore", {
      login: (state) => state.login,
    }),
  },
  watch: {
    selectedDraftDeptCode() {
      this.$emit("updateSelectedDraftDept", this.selectedDraftDeptCode);
    },
    draftDeptApprLine() {
      this.$emit("updateDraftDeptApprLine", this.draftDeptApprLine);
    },
    processingApprovalLine() {
      this.$emit("updateProcessingApprovalLine", this.processingApprovalLine);
    },
    referenceApprovalLine() {
      this.$emit("updateReferenceApprovalLine", this.referenceApprovalLine);
    },
    initData() {
      this.setInitData();
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    this.getApprovalDocDfltInfo();
  },
  async mounted() {
    const userData = LocalStorageManager.shared.getUserData();
    this.me.accountId = userData.username;
    this.me.empNo = userData.empNo;
    this.me.name = userData.empName || userData.name;

    this.me.empName = "";
    this.me.deptName = "";
    this.me.deptCode = "";

    await this.fetchAllDepartment();

    this.selectedDraftDeptCode = this.login.deptCode;
    this.draftDeptName = this.login.deptName;

    if (this.selectedApprovalLineAccount) {
      this.selectPayAccount(this.selectedApprovalLineAccount);
    }
  },
  methods: {
    async getApprovalDocDfltInfo() {
      const params = { useYn: "Y", page: 1, size: 99 };
      const result = await ApiService.shared.get(this.$apiPath.APRVL_LINE_ACCONT, {
        params,
      });

      if (result.code !== "200") {
        this._showLayer({ type: "confirm", content: result.text });
      }

      this.aprvlLineAccntList = result.data ? result.data.aprvlLineAccntList : [];
    },
    setDraftDeptList(concurrentDeptList) {
      const list = [];
      list.push({
        name: this.login.deptName,
        code: this.login.deptCode,
        concurrent: false,
      });
      concurrentDeptList.forEach((value) => {
        list.push({
          name: value.deptName,
          code: value.deptCode,
          concurrent: true,
        });
      });
      this.draftDeptList = list;
      if (
        this.$DraftBus._data.document &&
        !this.$DraftBus._data.document.tempDocNo &&
        !this.$DraftBus._data.document.reDraftOrgDocNo
      ) {
        this.selectDraftDept(this.draftDeptList[0], true);
      }
    },
    confirmChangePayAccount(payAccount) {
      return true;
    },
    confirmChangeDraftDept(dept) {
      return true;
    },
    async selectPayAccount(approvalLineAccounts, notInitCategory) {
      this.$emit("selectedApprovalLineAccount", approvalLineAccounts);
      this.payAccountName = approvalLineAccounts.name;

      this.processingApprovalLine = [];
      this.referenceApprovalLine = [];

      const url = `${this.$apiPath.APRVL_LINE_ACCONT}/${approvalLineAccounts.code}`;
      const res = await ApiService.shared.getData(url);

      if (res.code !== "200") {
        console.error(res);
        return;
      }

      const approvalLineList = res.data.cmmnAprvlLine.cmmnAprvlLineTargetList;

      approvalLineList
        .filter((line) => line.aprvlLineType === aprvlLineType.APPROVAL)
        .sort((a, b) => a.aprvlOrder - b.aprvlOrder)
        .forEach((approver) => this.addProcessingApproval(approver));

      approvalLineList
        .filter((line) => line.aprvlLineType === aprvlLineType.REFERENCE)
        .sort((a, b) => a.aprvlOrder - b.aprvlOrder)
        .forEach((approver) => this.addReferenceApproval(approver));
    },
    selectDraftDept(draftDept, dontChangeCostCenter) {
      this.selectedDraftDeptCode = draftDept.code;
    },
    removeApproval(emp) {
      const obj = this;
      switch (emp.approvalType) {
        case "draft":
          this.draftDeptApprLine.some((value, index) => {
            if (value.empNo === emp.empNo) {
              obj.draftDeptApprLine.splice(index, 1);
              return true;
            }
          });
          break;
        case "proc":
          this.processingApprovalLine.some((value, index) => {
            if (value.empNo === emp.empNo) {
              obj.processingApprovalLine.splice(index, 1);
              return true;
            }
          });
          break;
        case "cc":
          this.referenceApprovalLine.some((value, index) => {
            if (value.empNo === emp.empNo) {
              obj.referenceApprovalLine.splice(index, 1);
              return true;
            }
          });
          break;
      }
    },
    displaySearchResult(dataList) {
      if (!dataList || dataList.code !== "200") {
        console.error("ERROR");
        return;
      }

      if (!dataList.data || dataList.data.length === 0) {
        return;
      }

      dataList.data.forEach((value) => {
        value.display = `${value.empName} (${value.accountId})`;
      });
    },
    addDraftDeptAppr(emp) {
      let count = 0;

      if (this.me.empNo === emp.empNo) count++;

      this.draftDeptApprLine.forEach((line) => {
        if (line.empNo === emp.empNo) count++;
      });

      if (count === 0) {
        this.draftDeptApprLine.push({
          empNo: emp.empNo,
          empName: emp.empName,
          accountId: emp.accountId,
          typeMe: false,
          deptName: emp.deptName,
          deptCode: emp.deptCode,
          actorFlag: "A",
          actorState: "2",
          apprLineFlag: "2",
          approvalType: "draft",
        });
      } else {
        this._showToast({ content: "이미 추가된 사용자입니다." });
      }
    },
    addProcessingApproval(emp) {
      let count = 0;
      this.processingApprovalLine.forEach((line) => {
        if (line.empNo === emp.empNo) count++;
      });
      //console.log('count - ', count);
      if (count === 0) {
        this.processingApprovalLine.push({
          empNo: emp.empNo,
          empName: emp.empName,
          accountId: emp.accountId,
          typeMe: false,
          deptName: emp.deptName,
          deptCode: emp.deptCode,
          actorFlag: "A",
          actorState: "2",
          apprLineFlag: "3",
          approvalType: "proc",
        });
      } else {
        this._showToast({ content: "이미 추가된 사용자입니다." });
      }
    },
    addReferenceApproval(emp) {
      let count = 0;
      this.referenceApprovalLine.forEach((line) => {
        if (line.empNo === emp.empNo) count++;
      });
      if (count === 0) {
        this.referenceApprovalLine.push({
          empNo: emp.empNo,
          empName: emp.empName,
          accountId: emp.accountId,
          typeMe: false,
          deptName: emp.deptName,
          deptCode: emp.deptCode,
          actorFlag: "R",
          actorState: "3",
          apprLineFlag: "4",
          approvalType: "cc",
          isMember: emp.isMember,
        });
      } else {
        this._showToast({ content: "이미 추가된 사용자입니다." });
      }
    },
    searchOrganization(searchType) {
      this.searchType = searchType;
      let employeeList = [];
      switch (searchType) {
        case "draftApproval":
          employeeList.push(this.me);
          employeeList = employeeList.concat(this.draftDeptApprLine);
          break;
        case "procApproval":
          employeeList = employeeList.concat(this.processingApprovalLine);
          break;
        case "ccApproval":
          employeeList = employeeList.concat(this.referenceApprovalLine);
          break;
      }
      let layer = {};
      layer.type = "popup";
      layer.class = "inner_structure_layer";
      layer.header = "조직도";
      layer.componentFile = "common/CommPopOrganization.vue";
      layer.props = {
        orgType: 2,
        employeeList: employeeList,
      };
      this._showLayer(layer, this.attachEmployee);
    },
    attachEmployee(employeeList) {
      let callback;
      switch (this.searchType) {
        case "draftApproval":
          callback = this.addDraftDeptAppr;
          employeeList.splice(0, 1);
          this.draftDeptApprLine.splice(0);
          break;
        case "procApproval":
          callback = this.addProcessingApproval;
          this.processingApprovalLine.splice(0);
          break;
        case "ccApproval":
          callback = this.addReferenceApproval;
          this.referenceApprovalLine.splice(0);
          break;
      }
      employeeList.forEach((emp) => {
        callback(emp);
      });
    },
    async fetchAllDepartment() {
      const params = {
        page: 1,
        size: 999,
      };
      const res = await ApiService.shared.get(this.$apiPath.APRVL_ORGDEPT, { params });
      if (!this.checkApiCodeIsOk(res)) {
        return;
      }

      this.draftDeptList = res.data.map((e) => ({
        name: escapeAmp(e.deptName),
        code: e.deptCode,
      }));
    },
    checkApiCodeIsOk(res) {
      if (parseInt(res.code) !== 200) {
        this._showLayer({ type: "confirm", content: res.text });
        console.error("res error - ", res);
        return false;
      }

      return true;
    },
    async setInitData() {
      const initData = this.initData;
      const params = {
        useYn: "Y",
        page: 1,
        size: 99,
      };
      const res = await ApiService.shared.get(this.$apiPath.APRVL_LINE_ACCONT, { params });

      if (this.checkNormalResponse(res)) {
        const approvalLine = res.data.find((e) => e.aprvlLineAccntNo === initData.aprvlLineAccntNo);
        this.payAccountName = approvalLine.name;
      }

      this.draftDeptName = initData.draftDeptName;

      function apprLineConvertor(e, approvalType) {
        return {
          empNo: e.empNo,
          empName: e.empName,
          accountId: e.accountId,
          typeMe: false,
          deptName: e.deptName,
          deptCode: e.deptCode,
          approvalType,
        };
      }
      this.draftDeptApprLine = initData.draftLine.map((e) => apprLineConvertor(e, "draft"));
      this.processingApprovalLine = initData.approvalLine.map((e) => apprLineConvertor(e, "proc"));
      this.referenceApprovalLine = initData.referrerLine.map((e) => apprLineConvertor(e, "cc"));
    },
  },
};
</script>
