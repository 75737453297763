import { getMoneyToNumber } from "@/utils/stringUtils";

export default class DraftPurchasePriceListModel {
  constructor(obj) {
    this.objRaw = {};

    this.objItemData = {};

    this.draftingPrice = ""; // 기안금액
    this.note = ""; // 비고
    this.addPrice = ""; // 추가 금액

    if (obj) {
      this.setData(obj);
    }
  }

  setItemData(obj) {
    this.objItemData = obj;

    const { draftingPrice, note, addPrice } = obj;

    this.draftingPrice = draftingPrice || draftingPrice === 0 ? String(draftingPrice) : "";
    this.note = note || "";
    this.addPrice = addPrice + "" || "0";
  }

  setData(obj) {
    this.objRaw = obj;

    const { draftingPrice, note, addPrice } = obj;

    this.draftingPrice = draftingPrice || draftingPrice === 0 ? String(draftingPrice) : "";
    this.note = note || "";
    this.addPrice = addPrice + "" || "0";
  }

  getData() {
    let obj = {
      draftingPrice: getMoneyToNumber(this.draftingPrice),
      note: this.note,

      addPrice: "0",
    };

    return Object.assign({}, this.objItemData, this.objRaw, obj);
  }

  getAlertMessage() {
    return null;
  }

  isEmpty() {
    return !this.draftingPrice;
  }
}
