import { getMoneyToNumber } from "@/utils/stringUtils";

export default class DraftPurchasePaymentListModel {
  constructor(obj) {
    this.objRaw = {};

    this.objItemData = {};

    this.amount = ""; // 청구금액
    this.paymentMonth = ""; // 청구년월

    if (obj) {
      this.setData(obj);
    }
  }

  setItemData(obj) {
    this.objItemData = obj;

    const { amount, paymentMonth } = obj;

    this.amount = amount || amount === 0 ? String(amount) : "";
    this.paymentMonth = paymentMonth || "";
  }

  setData(obj) {
    this.objRaw = obj;

    const { amount, paymentMonth } = obj;

    this.amount = amount || amount === 0 ? String(amount) : "";
    this.paymentMonth = paymentMonth || "";
  }

  getData() {
    let obj = {
      amount: getMoneyToNumber(this.amount),
      paymentMonth: this.paymentMonth,
    };

    return Object.assign({}, this.objItemData, this.objRaw, obj);
  }

  getAlertMessage() {
    // if (this.amount === 0 || this.amount === '0' || !this.amount)
    //   return '품목의 수량을 확인해주세요.';
    // if (this.unitPrice !== 0 && !this.unitPrice)
    //   return '품목의 단가를 확인해주세요.';

    return null;
  }
}
