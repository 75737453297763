import DraftPurchasePriceListModel from "@/_approval/components/purchaseDraft/write/DraftPurchasePriceListModel";
import DraftPurchaseGoodsListModel from "@/_approval/components/purchaseDraft/write/DraftPurchaseGoodsListModel";
import DraftPurchasePaymentListModel from "@/_approval/components/purchaseDraft/write/DraftPurchasePaymentListModel";
import { getMoneyToNumber, addCommaForMoney } from "@/utils/stringUtils";
import { padLeft } from "@/utils/numberUtils";
import {
  accountType,
  aprvlLineType,
  assetType,
  docStatus,
  targetGubun,
} from "@/_approval/const/const";
import ApprovalLineModel from "@/_approval/pages/purchaseDraft/write/ApprovalLineModel";

export default class DraftWriteModel {
  constructor() {
    this.title = ""; // 구매요청명
    this.assetType = assetType.SERVICE_AND_GENERAL_ASSET; // 구매구분 (G 총무자산, I 자산구매, H 일반구매)
    this.itemTitle = ""; // 구매 카테고리
    this.handleNum = "";
    this.contents = ""; // 상세내용

    this.purchasePriceList = []; // 기안금액

    this.purchaseGoodsList = []; // 구매요청품목

    this.accountYn = false; // 정산상태
    this.accountStartDate = null; // 정산시작일
    this.accountEndDate = null; // 정산종료일
    this.accountType = accountType.REGULARLY; // 청구유형 (N 선택안함, O 1회정산, R 정기정산)
    this.purchasePaymentList = []; // 월별 청구금액
    this.attachFileList = []; // 첨부파일

    /**
     * PK
     * @type {string}
     * @private
     */
    this._targetPk = "";
    /**
     * 문서 상태
     * @type {docStatus}
     * @private
     */
    this._docStatus = null;
    /**
     * 구매요청부서
     * @type {string}
     * @private
     */
    this._deptPathName = "";
    /**
     * 구매요청 부서코드
     * @type {string}
     * @private
     */
    this._deptCode = "";
    /**
     * 구매요청 부서명
     * @type {string}
     * @private
     */
    this._deptName = "";
    /**
     * 결제선 지정 - 계정선택
     * name: 계정명, code: 계정아이디
     * @type {*[]}
     * @private
     */
    this._approvalLineAccounts = null;

    /**
     * 발제 부서라인
     * @type {*[]}
     * @private
     */
    this._draftDeptApprLine = [];
    /**
     * 승인자 처리라인
     * @type {*[]}
     * @private
     */
    this._processingApprovalLine = [];
    /**
     * 참조자
     * @type {*[]}
     * @private
     */
    this._referenceApprovalLine = [];
    /**
     * 나의 발제 부서 정보
     * 발제 부서 라인에  나의 정보는 빠져있으므로 생성시 같이 사용함.
     * @type {ApprovalLineModel}
     * @private
     */
    this._myDraftDeptInfo = null;
    /**
     * 이메일
     * @type {string}
     * @private
     */
    this._email = "";
    /**
     * 모바일 번호
     * @type {string}
     * @private
     */
    this._mobile = "";
    /**
     * 등록일
     * @type {string}
     * @private
     */
    this._regDate = "";
    /**
     * 기안자명
     * @type {string}
     * @private
     */
    this._drftEmpName = "";
    /**
     * 공개 여부
     * @type {boolean}
     * @private
     */
    this._publicYn = false;
    /**
     * 반려 전 기존 상신된 문서 PK
     * @type {string}
     * @private
     */
    this._reDrftOriDocNo = "";
  }

  setPriceList(itemList) {
    if (itemList && itemList.length > 0) {
      this.purchasePriceList = itemList.map((item) => new DraftPurchasePriceListModel(item));
    } else if (!itemList) {
      this.purchasePriceList.push(new DraftPurchasePriceListModel());
    }
  }

  setGoodsList(itemList) {
    if (itemList && itemList.length > 0) {
      this.purchaseGoodsList = itemList.map((item) => new DraftPurchaseGoodsListModel(item));
    } else if (!itemList) {
      this.purchasePriceList.push(new DraftPurchaseGoodsListModel());
    }
  }

  setPaymentList(itemList) {
    if (itemList && itemList.length > 0) {
      this.purchasePaymentList = itemList.map((item) => new DraftPurchasePaymentListModel(item));
    } else if (!itemList) {
      this.purchasePriceList.push(new DraftPurchasePaymentListModel());
    }
  }

  resetData() {
    this.purchasePriceList = [new DraftPurchasePriceListModel()];
    this.purchaseGoodsList = [new DraftPurchaseGoodsListModel()];
  }

  // 총 기안금액
  totalDraftingPrice() {
    let result = 0;
    this.purchasePriceList.forEach((item) => {
      result += getMoneyToNumber(item.draftingPrice);
    });
    return addCommaForMoney(result);
  }

  // 총 품목금액
  totalGoodsPrice() {
    let result = 0;
    this.purchaseGoodsList.forEach((item) => {
      result += getMoneyToNumber(item.price);
    });
    return addCommaForMoney(result);
  }

  // 기안금액 추가
  addPrice() {
    this.purchasePriceList.push(new DraftPurchasePriceListModel());
  }

  // 기안금액 삭제
  removePrice(itemToRemove) {
    this.purchasePriceList = this.purchasePriceList.filter((item) => item !== itemToRemove);
  }

  // 구매요청품목 추가
  addGood() {
    this.purchaseGoodsList.push(new DraftPurchaseGoodsListModel());
    this.purchasePriceList.push(new DraftPurchasePriceListModel());
  }

  // 구매요청품목 삭제
  removeGood(itemToRemove) {
    const removeGoodIndex = this.purchaseGoodsList.indexOf(itemToRemove);
    this.purchaseGoodsList = this.purchaseGoodsList.filter((item) => item !== itemToRemove);
    this.purchasePriceList.splice(removeGoodIndex, 1);
  }

  // 구매요청품목 금액 업데이트 - 기안금액 적용
  updatePrice(index) {
    const price = this.purchaseGoodsList[index].price;
    this.purchasePriceList[index].draftingPrice = price;
  }

  // 정기 청구 시 자동 월별 청구금액 계산
  setUnitformRate() {
    // 총 기안금액, 청구기간 입력 모두 되었을때 계산한다
    if (this.totalDraftingPrice() != 0 && this.purchasePaymentList.length == 0) return;

    // 정기수신이 아니면 직접 입력으로 값 비우기
    if (this.accountType != "R") {
      this.purchasePaymentList = this.purchasePaymentList.map((item) => {
        let obj = {};
        obj.amount = "";
        obj.paymentMonth = item.paymentMonth;
        return obj;
      });
      return;
    }

    //총 기안금액
    const totalDraftingPrice = getMoneyToNumber(this.totalDraftingPrice());

    // 분할할 월갯수
    const MounthLengthOrgin = this.purchasePaymentList.filter((e) => !e.disabled).length;
    const MounthLength = MounthLengthOrgin; // 12개월 이상이면 12

    // 총 기안금액 / 분할월
    const resultNumber = totalDraftingPrice / MounthLength;

    // 소수점이 발생할경우
    if (String(resultNumber).split(".")[1] != 0) {
      // 일의자리 + 소수점
      const tenNumber = resultNumber % 10;
      // 마지막 달 제외 달의 분할금액
      const resultExceptLast = addCommaForMoney(resultNumber - tenNumber);
      // 마지막 달 분할금액
      const resultLast = addCommaForMoney(
        totalDraftingPrice - (resultNumber - tenNumber) * (MounthLength - 1),
      );

      // 월별 청구 금액에 해당 분할 금액 적용
      this.purchasePaymentList = this.purchasePaymentList.map((item, index) => {
        const obj = {};

        if (item.disabled) {
          obj.amount = "0";
          obj.paymentMonth = item.paymentMonth;
          obj.disabled = item.disabled;
          return obj;
        }

        if (index == MounthLength - 1) {
          // 마지막 달
          obj.amount = resultLast;
        } else {
          // 그외 달
          obj.amount = resultExceptLast;
        }

        obj.paymentMonth = item.paymentMonth;
        obj.disabled = item.disabled;
        return obj;
      });

      // 소수점없이 딱 떨어지는 경우
    } else {
      const result = addCommaForMoney(resultNumber);

      // 월별 청구 금액에 해당 분할 금액 적용
      this.purchasePaymentList = this.purchasePaymentList.map((item, index) => {
        let obj = {};
        obj.amount = index < 12 ? result : ""; // 12개월보다 넘치는 달은 비움 - disabled 처리됨
        obj.paymentMonth = item.paymentMonth;
        return obj;
      });
    }
  }

  makeMonthlyPriceListByDate(yyyymmddStart, yyyymmddEnd) {
    const dateStart = new Date(yyyymmddStart);
    let dateEnd = new Date(yyyymmddEnd);

    let max = new Date(dateStart.getTime());
    max.setMonth(dateStart.getMonth() + 60);

    if (dateEnd > max) {
      this.accountEndDate = max;
      //dateEnd = new Date(this.accountEndDate);

      return "청구기간은 최대 60개월까지 설정가능합니다";
    }

    // 시작월, 종료월 둘 다 포함.
    const diff = this.monthDiff(dateStart, dateEnd) + 1;

    const arr = [];

    let paymentMonth;

    function _makePurchasePaymentInfo(_paymentMonth, disabled) {
      return {
        paymentMonth: `${_paymentMonth.getFullYear()}-${padLeft(_paymentMonth.getMonth() + 1, 2)}`,
        amount: "",
        disabled,
      };
    }

    for (let i = 0; i < diff; ++i) {
      paymentMonth = new Date(dateStart);
      paymentMonth.setMonth(paymentMonth.getMonth() + i);

      arr.push(_makePurchasePaymentInfo(paymentMonth, false));
    }

    let oneLineInputSize = 6;
    while (diff > oneLineInputSize) {
      oneLineInputSize += 6;
    }
    const remainInputSize = oneLineInputSize - diff;
    for (let i = 0; i < remainInputSize; i++) {
      paymentMonth = new Date(dateStart);
      paymentMonth.setMonth(paymentMonth.getMonth() + (diff + i));

      arr.push(_makePurchasePaymentInfo(paymentMonth, true));
    }

    this.purchasePaymentList = arr;
    this.setUnitformRate();
  }

  monthDiff(d1, d2) {
    var months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months <= 0 ? 0 : months;
  }

  setData(obj) {
    this.objRaw = obj;

    const {
      title,
      assetType,
      itemTitle,
      contents,
      purchasePriceList,
      purchaseGoodsList,
      accountYn,
      accountStartDate,
      accountEndDate,
      accountType,
      purchasePaymentList,
      attachFileList,
    } = obj;

    this.title = title || "";
    this.assetType = assetType || "";
    this.itemTitle = itemTitle || "";
    this.contents = contents || "";

    if (purchasePriceList && purchasePriceList.length > 0) {
      this.purchasePriceList = purchasePriceList.map(
        (item) => new DraftPurchasePriceListModel(item),
      );
    }

    if (purchaseGoodsList && purchaseGoodsList.length > 0) {
      this.purchaseGoodsList = purchaseGoodsList.map(
        (item) => new DraftPurchaseGoodsListModel(item),
      );
    }

    if (purchasePaymentList && purchasePaymentList.length > 0) {
      this.purchasePaymentList = purchasePaymentList.map(
        (item) => new DraftPurchasePaymentListModel(item),
      );
    }

    this.accountYn = accountYn || true;
    this.accountStartDate = accountStartDate || null;
    this.accountEndDate = accountEndDate || null;
    this.accountType = accountType || "";
    this.attachFileList = attachFileList || [];
  }

  getData() {
    let obj = {
      title: this.title,
      assetType: this.assetType,
      itemTitle: this.itemTitle,
      contents: this.contents,
      accountYn: this.accountYn,
      accountStartDate: this.accountStartDate,
      accountEndDate: this.accountEndDate,
      accountType: this.accountType,
    };

    if (this.purchasePriceList) {
      obj.purchasePriceList = this.purchasePriceList.map((item) => item.getData());
    }

    if (this.purchaseGoodsList) {
      obj.purchaseGoodsList = this.purchaseGoodsList.map((item) => item.getData());
    }

    if (this.purchasePaymentList) {
      obj.purchasePaymentList = this.purchasePaymentList.map((item) => item.getData());
    }

    if (this.attachFileList) {
      obj.attachFileList = this.attachFileList.map((item) => item.getData());
    }

    return Object.assign({}, this.objRaw, obj);
  }

  getAlertMessage() {
    if (!this.title) return "구매요청명을 확인해주세요.";

    let item;
    let alertMessage;

    for (var i = 0; i < this.purchaseGoodsList.length; ++i) {
      item = this.purchaseGoodsList[i];
      if (this.amount === 0 || this.amount === "0" || !this.amount)
        return "품목의 수량을 확인해주세요.";
      if (this.unitPrice !== 0 && !this.unitPrice) return "품목의 단가를 확인해주세요.";

      alertMessage = item.getAlertMessage();

      if (alertMessage) return alertMessage;
    }

    return null;
  }

  /**
   * 유효성 검사 체크
   * - 발제 부서 라인의 경우 필수이지만 기본으로 자신이 포함되어 있으므로 유효성 검새에서 제외한다.
   * @return {string|undefined}
   */
  validate(isTemp) {
    if (!this._approvalLineAccounts) {
      return "결제선 지정의 계정을 선택해주세요.";
    }
    if (!this._deptCode) {
      return "신청자 부서를 입력해주세요.";
    }
    if (!this._processingApprovalLine || this._processingApprovalLine.length === 0) {
      return "승인자 처리 라인을 선택해주세요.";
    }
    if (!this.title) {
      return "구매요청명을 입력해주세요.";
    }
    // if (!this.handleNum) { return '구매 카테고리를 선택해주세요.'; }
    // if (this._isInValidatePurchasePriceList()) {
    //   return '기안 금액, 프로젝트 코드를 입력해주세요.';
    // }
    // if (!this.accountYn && (!this.accountStartDate || !this.accountEndDate)) {
    //   return '청구 기간을 선택해주세요.';
    // }

    // 인프라 구매요청 상신시 총 기안금액 = 구매요청 품목의 합계 유효성 체크
    if (this.assetType == assetType.INFRA_ASSET && !isTemp) {
      const sumOfGoodsPrice = this.purchaseGoodsList.reduce(
        (a, c) => Number(a) + Number(c.price.replaceAll(",", "")),
        0,
      );
      const sumOfDraftingPrcie = this.purchasePriceList.reduce(
        (a, c) => Number(a) + Number(c.draftingPrice.replaceAll(",", "")),
        0,
      );

      if (sumOfGoodsPrice != sumOfDraftingPrcie) {
        return "총 기안금액과 구매요청품목의 합계가 일치하지 않습니다.";
      }
      if (this._isInValidateProjectCode()) {
        return "구매요청품목의 프로젝트 코드를 입력해주세요.";
      }
    }

    return void 0;
  }

  makeFormData() {
    return {
      docStatus: this._docStatus,
      aprvlLineAccntNo: this._approvalLineAccounts.code,
      reDrftOriDocNo: this._reDrftOriDocNo,
      targetGubun: targetGubun.DF_PURCHASE_REQUEST_WF,
      publicYn: false,
      aprvlLineList: this._makeAprvlLineList(),
      data: this._makeDraftDetailData(this._docStatus === docStatus.TEMPORARY),
    };
  }

  _makeDraftDetailData(isTemp = false) {
    return {
      accountStartDate: this.accountStartDate,
      accountEndDate: this.accountEndDate,
      agitId: this.agitId,
      accountType: this.accountType,
      accountYn: this.accountYn,
      assetType: this.assetType,
      attachFileList: this.attachFileList,
      title: this.title,
      contents: this.contents,
      deptCode: this._deptCode,
      deptName: this._deptName,
      deptPathName: this._deptPathName,
      email: this._email,
      handleNum: this.handleNum,
      isTemp: isTemp === true ? "Y" : "N",
      itemTitle: this.itemTitle,
      mobile: this._mobile,
      movePlanYn: false, // 월별 이동계획 포함유무
      purchasePaymentList: this.purchasePaymentList.map((e) =>
        e instanceof DraftPurchasePaymentListModel
          ? e.getData()
          : new DraftPurchasePaymentListModel(e).getData(),
      ),
      purchaseGoodsList:
        this.assetType !== assetType.INFRA_ASSET
          ? []
          : this.purchaseGoodsList.map((e) => e.getData()),
      purchasePriceList: this.purchasePriceList.map((e) => e.getData()),
    };
  }

  _makeAprvlLineList() {
    const approvalLineList = [];
    approvalLineList.push(...this._makeDraftDeptApprovalLineList());
    approvalLineList.push(...this._makeProcessingApprovalLine());
    approvalLineList.push(...this._makeReferenceApprovalLine());

    return approvalLineList.map((e, index) => e.toJson(index + 1));
  }

  _makeReferenceApprovalLine() {
    if (!this._referenceApprovalLine || this._referenceApprovalLine.length === 0) {
      return [];
    }

    return this._referenceApprovalLine.map(
      (e) => new ApprovalLineModel(e.empNo, e.deptCode, aprvlLineType.REFERENCE),
    );
  }

  _makeProcessingApprovalLine() {
    return this._processingApprovalLine.map(
      (e) => new ApprovalLineModel(e.empNo, e.deptCode, aprvlLineType.APPROVAL),
    );
  }

  _makeDraftDeptApprovalLineList() {
    const approvalLine = [];
    if (this._myDraftDeptInfo) {
      approvalLine.push(this._myDraftDeptInfo);
    }

    if (this._draftDeptApprLine && this._draftDeptApprLine.length > 0) {
      const otherLine = this._draftDeptApprLine.map(
        (e) => new ApprovalLineModel(e.empNo, e.deptCode, aprvlLineType.APPROVAL, "", true),
      );

      approvalLine.push(...otherLine);
    }

    return approvalLine;
  }

  _isInValidatePurchasePriceList() {
    return (
      !this.purchasePriceList ||
      this.purchasePriceList.length === 0 ||
      this.purchasePriceList.some((e) => e.isEmpty())
    );
  }
  _isInValidateProjectCode() {
    const filtered = this.purchaseGoodsList.filter((item) => item.projectCode === "");

    return filtered.length > 0;
  }

  initFromFetchData(data) {
    const detailData = data.data;

    this._approvalLineAccounts = { code: data.aprvlLineAccntNo };
    this._targetPk = data.targetPk;
    this._regDate = data.regDate;
    this._drftEmpName = data.drftEmpName;
    this._deptName = data.drftDeptName;
    this._publicYn = data.publicYn;

    this._reDrftOriDocNo = detailData.reDrftOriDocNo;
    this._deptCode = detailData.deptCode;
    this._deptPathName = detailData.deptPathName;
    this._email = detailData.email;
    this._mobile = detailData.mobile;
    this.title = detailData.title;
    this.assetType = detailData.assetType;
    this.itemTitle = detailData.itemTitle;
    this.handleNum = detailData.handleNum;
    this.contents = detailData.contents;
    this.accountYn = detailData.accountYn;
    this.accountType = detailData.accountType;
    this.accountStartDate = detailData.accountStartDate;
    this.accountEndDate = detailData.accountEndDate;
    this.agitId = detailData.agitId;
    this.purchasePriceList = detailData.purchasePriceList.map(
      (e) => new DraftPurchasePriceListModel(e),
    );
    this.purchaseGoodsList = detailData.purchaseGoodsList.map(
      (e) => new DraftPurchaseGoodsListModel(e),
    );
    this.purchasePaymentList = detailData.purchasePaymentList.map(
      (e) => new DraftPurchasePaymentListModel(e),
    );
    if (detailData.attachFileList) {
      this.attachFileList = detailData.attachFileList;
    }
  }

  set deptPathName(depthPathName) {
    this._deptPathName = depthPathName;
  }
  set deptCode(deptCode) {
    this._deptCode = deptCode;
  }
  set draftDeptApprLine(draftDeptApprLine) {
    this._draftDeptApprLine = draftDeptApprLine;
  }
  set processingApprovalLine(value) {
    this._processingApprovalLine = value;
  }
  set referenceApprovalLine(value) {
    this._referenceApprovalLine = value;
  }
  set approvalLineAccounts(value) {
    this._approvalLineAccounts = value;
  }
  set docStatus(value) {
    this._docStatus = value;
  }
  set myDraftDeptInfo(value) {
    this._myDraftDeptInfo = value;
  }
  set deptName(value) {
    this._deptName = value;
  }
  set email(value) {
    this._email = value;
  }
  set mobile(value) {
    this._mobile = value;
  }
  set targetPk(value) {
    this._targetPk = value;
  }
  set regDate(value) {
    this._regDate = value;
  }
  set reDrftOriDocNo(value) {
    this._reDrftOriDocNo = value;
  }

  get deptPathName() {
    return this._deptPathName;
  }
  get targetPk() {
    return this._targetPk ? this._targetPk : "-";
  }
  get regDate() {
    return this._regDate;
  }
  get drftEmpName() {
    return this._drftEmpName;
  }
  get publicYnAsStr() {
    return this._publicYn === true ? "Y" : "N";
  }
}
